/*
export const adPositions = [
  {
    name: 'Billboard',
    id: 2916577,
    yieldLab: [{ yieldlabAdslotVal: 333095 }],
  },
  {
    name: 'SmartphoneBanner',
    id: 3372311,
    yieldLab: [{ yieldlabAdslotVal: 653101 }, { yieldlabAdslotVal: 653088 }],
  },
  {
    name: 'Leaderboard',
    id: 2916576,
    yieldLab: [{ yieldlabAdslotVal: 133674 }],
  },
  {
    name: 'Super_Leaderboard',
    id: 2916576,
    yieldLab: [{ yieldlabAdslotVal: 133674 }],
  },
  {
    name: 'Medium_Rectangle',
    id: 2916579,
    yieldLab: [{ yieldlabAdslotVal: 133675 }],
  },
  {
    name: 'Medium_Rectangle_mobile',
    id: 3372311,
    yieldLab: [{ yieldlabAdslotVal: 653101 }, { yieldlabAdslotVal: 653088 }],
  },
  {
    name: 'Sitebar',
    id: 2916578,
    yieldLab: [{ yieldlabAdslotVal: 133676 }, { yieldlabAdslotVal: 316514 }, { yieldlabAdslotVal: 333067 }],
  },
  {
    name: 'Halfpage_right',
    id: 2916578,
    yieldLab: [{ yieldlabAdslotVal: 133676 }, { yieldlabAdslotVal: 316514 }, { yieldlabAdslotVal: 333067 }],
  },
  {
    name: 'Halfpage_left',
    id: 2916583,
    yieldLab: [{ yieldlabAdslotVal: 9745406 }, { yieldlabAdslotVal: 9743356 }],
  },
  {
    name: 'Skyscraper',
    id: 2916578,
    yieldLab: [{ yieldlabAdslotVal: 133676 }, { yieldlabAdslotVal: 316514 }, { yieldlabAdslotVal: 333067 }],
  },
  {
    name: 'Halfpage_mobile',
    id: 3372312,
    yieldLab: [{ yieldlabAdslotVal: 7057249 }, { yieldlabAdslotVal: 7054109 }],
  }, //not sure about sizing
  {
    name: 'Understitial',
    id: 2916578,
    yieldLab: [{ yieldlabAdslotVal: 133676 }, { yieldlabAdslotVal: 316514 }, { yieldlabAdslotVal: 333067 }],
  },
  {
    name: 'Understitial_mobile',
    id: 3372312,
    yieldLab: [{ yieldlabAdslotVal: 7057249 }, { yieldlabAdslotVal: 7054109 }, { yieldlabAdslotVal: 333067 }],
  },
];
*/

//breakpoints replaces the need to check for the width of the container/site/ad
// -1 and 10 replace a fixed boundary
//FIXME: maybe needs to be handled differently in the future, but this seems to be the fastest and easiest way
//TODO: not finished yet, needs to be done right after fixing positions and preview
export const adPositions = [
  //desktop
  //kino ids[0,1]
  {
    name: 'Billboard',
    id: 4292086,
    yieldLab: [{ yieldlabAdslotVal: 333095 }],
    breakpoints: [5, 10],
  },
  {
    name: 'Left1',
    id: 4292089,
    yieldLab: [{ yieldlabAdslotVal: 9743356 }],
    breakpoints: [2, 10],
  },
  //startseite ids[2,3,4,5]
  {
    name: 'Billboard',
    id: 2916577,
    yieldLab: [{ yieldlabAdslotVal: 333095 }],
    breakpoints: [3, 10],
  },
  {
    name: 'Cad1',
    id: 2916579,
    yieldLab: [{ yieldlabAdslotVal: 133675 }],
  },
  {
    name: 'Cad2',
    id: 2916584,
    yieldLab: [],
  },
  {
    name: 'Cad3',
    id: 2916585,
    yieldLab: [],
  },
  //tvprogramm ids[6,7]
  {
    name: 'Billboard',
    id: 2916591,
    yieldLab: [{ yieldlabAdslotVal: 333095 }],
    breakpoints: [3, 10],
  },
  {
    name: 'Right1',
    id: 2916592,
    yieldLab: [{ yieldlabAdslotVal: 133676 }, { yieldlabAdslotVal: 316514 }, { yieldlabAdslotVal: 333067 }],
    breakpoints: [3, 10],
  },
  //ROS ids[8,9,10,11,12,13,14,15]
  {
    name: 'Billboard',
    id: 3343297,
    yieldLab: [],
    breakpoints: [3, 10],
  },
  {
    name: 'BSB',
    id: 3343296,
    yieldLab: [],
    breakpoints: [3, 10],
  },
  {
    name: 'Cad1',
    id: 3343299,
    yieldLab: [],
    breakpoints: [3, 10],
  },
  {
    name: 'Cad2',
    id: 3343304,
    yieldLab: [],
    breakpoints: [3, 10],
  },
  {
    name: 'Cad3',
    id: 3343305,
    yieldLab: [],
    breakpoints: [3, 10],
  },
  {
    name: 'Cad_Slideshow',
    id: 3343308,
    yieldLab: [],
  },
  {
    name: 'Left1',
    id: 3343303,
    yieldLab: [],
    breakpoints: [5, 10],
  },
  {
    name: 'Right1',
    id: 3343298,
    yieldLab: [],
    breakpoints: [3, 10],
  },
  //mobile
  //kino ids[16,17,18,19]
  {
    name: 'mma_1_cad_1_header_mobile',
    id: 4776470,
    yieldLab: [{ yieldlabAdslotVal: 133674 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_2_middle_mobile',
    id: 4776471,
    yieldLab: [{ yieldlabAdslotVal: 133674 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_3_middle_mobile',
    id: 4776474,
    yieldLab: [{ yieldlabAdslotVal: 133676 }, { yieldlabAdslotVal: 316514 }, { yieldlabAdslotVal: 333067 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_3_cad_3_footer_mobile',
    id: 4776472,
    yieldLab: [{ yieldlabAdslotVal: 9745406 }, { yieldlabAdslotVal: 9743356 }],
    breakpoints: [-1, 3],
  },
  //startseite ids[20,21,22,23]
  {
    name: 'mma_1_cad_1_header_mobile',
    id: 3372311,
    yieldLab: [{ yieldlabAdslotVal: 653101 }, { yieldlabAdslotVal: 653088 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_2_middle_mobile',
    id: 3372312,
    yieldLab: [{ yieldlabAdslotVal: 7057249 }, { yieldlabAdslotVal: 7054109 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_3_middle_mobile',
    id: 4281490,
    yieldLab: [],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_3_cad_3_footer_mobile',
    id: 3372313,
    yieldLab: [],
    breakpoints: [-1, 3],
  },
  //tvprogramm ids[24,25,26,27]
  {
    name: 'mma_1_cad_1_header_mobile',
    id: 3372314,
    yieldLab: [{ yieldlabAdslotVal: 653101 }, { yieldlabAdslotVal: 653088 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_2_middle_mobile',
    id: 3372315,
    yieldLab: [{ yieldlabAdslotVal: 7057249 }, { yieldlabAdslotVal: 7054109 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_3_middle_mobile',
    id: 4281491,
    yieldLab: [],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_3_cad_3_footer_mobile',
    id: 3372316,
    yieldLab: [],
    breakpoints: [-1, 3],
  },
  //ros ids[28,29,30,31]
  {
    name: 'mma_1_cad_1_header_mobile',
    id: 3372317,
    yieldLab: [{ yieldlabAdslotVal: 653101 }, { yieldlabAdslotVal: 653088 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_2_middle_mobile',
    id: 3372318,
    yieldLab: [{ yieldlabAdslotVal: 7057249 }, { yieldlabAdslotVal: 7054109 }],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_2_cad_3_middle_mobile',
    id: 4281492,
    yieldLab: [],
    breakpoints: [-1, 3],
  },
  {
    name: 'mma_3_cad_3_footer_mobile',
    id: 3372319,
    yieldLab: [],
    breakpoints: [-1, 3],
  },
  //06.02.2023 - Adding Cad1 for TV-Programm [32]
  {
    name: 'Cad1',
    id: 2916593,
    yieldLab: [{ yieldlabAdslotVal: 133675 }],
  },
  //22.03.2023 - Adding Cad1 for Kino [33]
  {
    name: 'Cad1',
    id: 4292102,
    yieldLab: [{ yieldlabAdslotVal: 133675 }],
  },
  //12.12.2023 - Adding AdSlots for adventcalendar [34,35]
  {
    name: 'Halfpage_left',
    id: 2916578,
    breakpoints: [3, 10],
    yieldLab: [{ yieldlabAdslotVal: 9745406 }, { yieldlabAdslotVal: 9743356 }],
  },
  {
    name: 'Halfpage_mobile',
    id: 3372312,
    breakpoints: [-1, 3],
    yieldLab: [{ yieldlabAdslotVal: 7057249 }, { yieldlabAdslotVal: 7054109 }],
  },
];
/*
const adPositions_Cinema = [];
const adPositions_TVProgram = [];
const adPositions_Page = [];
*/
