/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { listenCookieChange } from '@vgn-medien-holding/vgn-fe-components';
import { getCookie } from 'cookies-next';
import { callAditionFunction, getBreakPoint, sanitizeKeywords } from '@lib/adition/adFarm';

export interface AditionTag {
  id: number;
  name: string;
  width?: number | string;
  height?: number | string;
  aspectRatio?: string;
  minWidth?: number;
  maxWidth?: number;
  yieldLab?: {
    yieldlabAdslotVal: number;
  }[];
  breakpoints?: number[];
}

export interface AditionInitProps {
  tags: AditionTag[];
  data?;
  fixedPageType?: string;
}

export const AditionInitialization: FC<AditionInitProps> = ({ tags, data, fixedPageType }) => {
  const profiles = [];
  const router = useRouter();
  const urlParts = router?.asPath.split('/');
  let pageType =
    typeof fixedPageType != 'undefined'
      ? fixedPageType
      : urlParts?.length > 2
        ? 'page'
        : urlParts?.[1] != ''
          ? 'ressort'
          : 'home';
  const ressortType = pageType == 'ressort' ? urlParts?.[1] : '';
  const [reRenderCounter, setReRenderCounter] = useState(0);
  let breakPoint = 0;

  const getOptimizedStringFromList = (list) => {
    let retVal = '';
    list?.forEach(
      (entry) =>
        retVal.indexOf(entry.title || entry) == -1 &&
        (retVal += !retVal ? entry.title || entry : ',' + (entry.title || entry)), //make sure value doesn't already exist in list
      //differentiate between entry.title for genres and entry for topics/keywords
    );
    return sanitizeKeywords(retVal);
  };

  profiles['sitebar'] = 'false';
  profiles['assetid'] = 'null';
  if (data) {
    const { id, slug, alt_keywords, genres, siteBar, article } = data;
    if (pageType == 'page') {
      //real article vs. provider/persons/etc. pages
      if (article) pageType = 'article';

      profiles['assetid'] = id;
      profiles['slug'] = slug;
      //for articles
      if (alt_keywords) profiles['keywords'] = getOptimizedStringFromList(alt_keywords);
      //for movies
      if (genres) {
        profiles['keywords'] = getOptimizedStringFromList(genres);
        pageType = 'entertainment'; //for TVM only -> no generalization between articles, provider/persons/etc. and movies/shows
      }

      //other topics?
      if (siteBar) profiles['sitebar'] = 'true';
    }
  }
  if (!profiles['slug']) profiles['slug'] = urlParts?.[urlParts?.length - 1].split('?')[0];
  profiles['pagetype'] = pageType;

  useEffect(() => {
    breakPoint = getBreakPoint(document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth);
    window.addEventListener('resize', () => {
      const newBeakPoint = getBreakPoint(
        document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth,
      );
      if (breakPoint != newBeakPoint) {
        breakPoint = newBeakPoint;
        setReRenderCounter((prevState) => prevState + 1);
      }
    });

    listenCookieChange(({ oldValue, newValue }) => {
      if (isNaN(oldValue) && newValue == 0) return;
      setReRenderCounter((prevState) => prevState + 1);
    }, 'C0004:');

    listenBannerCookieChange(() => setReRenderCounter((prevState) => prevState + 1));
  }, []);

  useEffect(() => {
    const pageWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const bannerClosed = getCookie('OptanonAlertBoxClosed');
    if (!bannerClosed) return;
    callAditionFunction(
      pageType,
      Boolean(Number(getCookie('OptanonConsent')?.toString().split('C0004:').pop().slice(0, 1))),
      pageWidth,
      profiles,
      returnListOfRenderAbleAdSlots(tags, getBreakPoint(pageWidth), pageType, ressortType),
    );
  }, [reRenderCounter]);

  const aditionInitLib = `
    var adition = adition || {};
    adition.srq = adition.srq || [];
    (function() {
      var script = document.createElement("script"); script.type = "text/javascript";
      script.src = (document.location.protocol === "https:" ?
        "https:" : "http:")
        + "//imagesrv.adition.com/js/srp.js";
      script.charset = "utf-8";
      script.async = true;
      var firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    })();
    `;

  return (
    <>
      <link rel="preconnect" href="https://imagesrv.adition.com" />
      <Script src={`https://imagesrv.adition.com/js/aut.js?ts=${Date.now()}`} async />
      <Script strategy={'afterInteractive'} id={'aditionLib'} dangerouslySetInnerHTML={{ __html: aditionInitLib }} />
    </>
  );
};

function returnListOfRenderAbleAdSlots(ads, currentBreakpoint, pageType, ressortType) {
  const retVal = [];
  let startIdDesktop = 8,
    endIdDesktop = 15,
    startIdMobile = 28,
    endIdMobile = 31; //of ad position-range on current page (default = ROS)
  let ignoreIds = [];
  switch (pageType) {
    case 'home':
      startIdDesktop = 2;
      endIdDesktop = 5;
      startIdMobile = 20;
      endIdMobile = 23;
      break;
    case 'ressort':
      switch (ressortType) {
        case 'programm':
          startIdDesktop = 6;
          endIdDesktop = 7;
          startIdMobile = 24;
          endIdMobile = 27;
          break;
        case 'kino':
          startIdDesktop = 0;
          endIdDesktop = 1;
          startIdMobile = 16;
          endIdMobile = 19;
          break;
        default: //Magazin + other Ressorts don't have Billboards, BSB, Cad-Slideshow, Left1, Right1
          ignoreIds = [8, 9, 13, 14, 15];
          break;
      }
      break;
    case 'article': //Articles don't have Cads and Cad-Slideshows
      ignoreIds = [10, 11, 12, 13];
      break;
    case 'entertainment': //TVM-only: Right1 Position available on movies and shows
      ignoreIds = [9, 10, 11, 12, 13, 14];
      break;
    case 'adventkalender': //TVM-only: for adventcalendar custom ads
      startIdDesktop = 34;
      endIdDesktop = 34;
      startIdMobile = 35;
      endIdMobile = 35;
      break;
    default: //pages only have billboards
      ignoreIds = [9, 10, 11, 12, 13, 14, 15];
      break;
  }
  ads.forEach((ad, idx) => {
    if ((startIdDesktop <= idx && idx <= endIdDesktop) || (startIdMobile <= idx && idx <= endIdMobile)) {
      if (ad.breakpoints) {
        //removing lazyloaded positions from initial request
        if (ad.name.includes('Billboard') || ad.name.includes('Cad') || ad.name.includes('mobile')) return;

        if (ad.breakpoints[0] <= currentBreakpoint && currentBreakpoint <= ad.breakpoints[1])
          if (!ignoreIds.includes(idx)) retVal.push(ad);
      }
    }
  });
  return retVal;
}

export function listenBannerCookieChange(
  callback: ({ oldValue, newValue }: { oldValue: number; newValue: number }) => void,
  interval = 1000,
) {
  let lastCookie = getCookie('OptanonAlertBoxClosed');
  return setInterval(() => {
    const newCookie = getCookie('OptanonAlertBoxClosed');
    if (newCookie !== lastCookie) {
      try {
        callback({ oldValue: Number(lastCookie), newValue: Number(newCookie) });
      } finally {
        lastCookie = newCookie;
      }
    }
  }, interval);
}
