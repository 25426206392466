//FIXME: Bessere Lösung finden bzgl. undeclared vars (ua. AppContext),
// oder ts-nocheck bei Änderungen entfernen und wenn alles richtig ist wieder hinzufügen
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* global __tcfapi:writable, adition:writable */

//changes .replace to .replaceAll

export const getBreakPoint = (pageWidth) => {
  //0 = xs, 1 = sm, 2 = md, 3=lg, 4=xl, 5=2xl
  switch (true) {
    case pageWidth <= 479:
      return 0; //xs
    case 479 < pageWidth && pageWidth <= 768: //sm
      return 1;
    case 768 < pageWidth && pageWidth <= 992: //md
      return 2;
    case 992 < pageWidth && pageWidth <= 1280: //lg
      return 3;
    case 1280 < pageWidth && pageWidth <= 1440: //xl
      return 4;
    case 1440 < pageWidth && pageWidth <= 1620: //2xl
      return 5;
    default: //3xl
      return 6;
  }
};

export const sanitizeKeywords = (keyword, spaceReplacement = ',') => {
  if (keyword && keyword !== undefined && typeof keyword === 'string') {
    keyword
      .toLowerCase()
      .replace(/ß/g, 'ss')
      .replace(/ü/g, 'ue')
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/\s{1,}&\s{1,}/g, '-')
      .replace(/&/g, '')
      .replace(/\n/g, ',')
      .replace(/,\s{1,}/g, ',')
      .replace(/,\s{1,}/g, ',')
      .replace(/[=:/]{1,}/g, '.')
      .replace(/\s{2,}/g, spaceReplacement);
  }
  return '';
};

const setProfilesForMap = (api, map) =>
  Object.entries(map).forEach((entry) => {
    const [key, value] = entry;
    api.setProfile(key, value);
  });

export const drawYieldLabSlots = (api, tags /* mobile = false*/) => {
  const yieldLab_newid = 133612;
  tags.forEach((pos) => {
    pos.yieldLab.forEach((yieldLabSlot) =>
      api.modules.configureModule('yieldlab').mapContentunit(pos.id, yieldLabSlot.yieldlabAdslotVal, yieldLab_newid),
    );
  });
  api.modules.configureModule('yieldlab').enable();
};

export const setConsentProfile = (api, marketingCookiesAllowed, tags, pageWidth, drawYieldLabSlots) => {
  if (!marketingCookiesAllowed) {
    api.setProfile('consent', 'optout');
    return;
  }
  drawYieldLabSlots(api, tags, pageWidth > 640);
  api.setProfile('consent', 'optin');
};

export const callAditionFunction = (pageType, marketingCookiesAllowed, pageWidth, profiles, tags) => {
  //making sure that only current tags are requested
  const tagNames = [];
  tags.forEach((tag) => {
    tagNames.push(tag.name);
  });

  function aditionLoaderServiceWorker(adition) {
    adition.srq.push(function (api) {
      // api.modules.setLoadingTimeout(2000);
      api.refreshSiteContext();
      api.registerAdfarm('ad1.adfarm1.adition.com');
      api.setProfile('pagewidth', pageWidth.toString());
      setProfilesForMap(api, profiles);
      tags.forEach((tag) => api.configureRenderSlot(tag.name).setContentunitId(tag.id));
      setConsentProfile(api, marketingCookiesAllowed, tags, pageWidth, drawYieldLabSlots);
      try {
        let gdprConsentString = '';
        __tcfapi('getTCData', null, function (result) {
          gdprConsentString = result.tcString;
          api.consentService
            .setGdprConsent()
            .setGdpr(1) // sets GDPR compliance for the user
            .setGdprPd(1) // GDPR_PD: personal data, default 1
            .setGdprConsent(gdprConsentString);
        });
        if (tags.length == 0) return;
      } catch (e) {
        console.error(e);
        //insignificant error
      }
    });
  }

  function completeRenderingForAdSlots(adition) {
    if (tagNames.length == 0) return;
    adition.srq.push(function (api) {
      api.load(tagNames).completeRendering();
    });
  }

  registerServiceWorker(adition, aditionLoaderServiceWorker, completeRenderingForAdSlots);
};

const registerServiceWorker = (adition, executeAditionFunc, renderAds) => {
  let adFunctionCalled = false; // Flag to track if the function has been called
  let adsRendered = false; // Flag to track if the function has been called
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then((registration) => {
        navigator.serviceWorker.addEventListener('message', (event) => {
          console.log(event.data);
          if (event.data && event.data.callAdFunction) {
            executeAditionFunc(adition);
            adFunctionCalled = true; // Set the flag to true after calling the function
          }

          if (event.data && event.data.finishAdRender) {
            adsRendered = true; // Set the flag to true after calling the function
            renderAds(adition);
          }
        });

        navigator.serviceWorker.ready.then(() => {
          const checkTcfApi = () => {
            if (typeof __tcfapi !== 'undefined' && !adFunctionCalled) {
              if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ tcfApiExists: true });
              } else {
                clearInterval(checkInterval);
                registration.unregister().then(() => {
                  console.log('Service Worker unregistered.');
                });
              }
            }
          };
          const checkConsent = () => {
            if (typeof __tcfapi !== 'undefined' && !adsRendered) {
              //navigator.serviceWorker.controller.postMessage({ gdprConsentExists: true });
              __tcfapi('ping', 2, (data) => {
                console.log(data.cmpLoaded);
                if (data.cmpLoaded) {
                  console.log('call');
                  if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({ finishAdRender: true });
                  } else {
                    clearInterval(checkInterval);
                    registration.unregister().then(() => {
                      console.log('Service Worker unregistered.');
                    });
                  }
                }
              });
            }
            if (typeof __tcfapi !== 'undefined' && !adFunctionCalled) {
              if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ tcfApiExists: true });
              } else {
                clearInterval(checkIntervalConsent);
                registration.unregister().then(() => {
                  console.log('Service Worker unregistered.');
                });
              }
            }
          };
          const checkInterval = setInterval(checkTcfApi, 100);
          const checkIntervalConsent = setInterval(checkConsent, 100);
        });
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
        setTimeout(() => {
          executeAditionFunc(adition);
          renderAds(adition);
        }, 500);
      });
  } else {
    setTimeout(
      () => {
        if (typeof adition != 'undefined') {
          executeAditionFunc(adition);
          renderAds(adition);
        }
      },
      typeof __tcfapi == 'undefined' ? 400 : 0,
    );
  }
};

export const reloadEveryPlacedAdslot = () => {
  adition.srq.push(function (api) {
    api.load().completeRendering();
  });
};
