import React from 'react';
import { Oewa as OewaDefault } from '@vgn-medien-holding/vgn-fe-components';

export interface OewaProps {
  categoryKey: string;
  pageKey: string;
  profileKey: string;
}

export const Oewa = ({ categoryKey, pageKey, profileKey }: OewaProps) => {
  return (
    <OewaDefault
      categoryKey={pageKey}
      pageKey={categoryKey}
      profileKey={profileKey}
      domainServiceName={'data-d3b9450695.tv-media.at'}
      siteName={'at_w_attv_media'}
    />
  );
};
